<template>
  <div>
    <b-button
        v-b-toggle.searchForm
        size="sm"
        variant="warning"
        @click="openFilterSearchForm()"
    >
      <span class="align-middle">저장된 필터 목록</span>
    </b-button>

    <b-sidebar
        id="searchForm"
        ref="searchForm"
        backdrop
        bg-variant="white"
        shadow
        width="600px"
    >
      <b-row>
        <b-col cols="12">
          <b-table-simple
              class="table-style"
              class-name="row-style"
              responsive
              small
          >
            <colgroup>
              <col>
              <col>
            </colgroup>
            <b-thead head-variant="light">
              <b-tr>
                <b-th>필터명</b-th>
                <b-th>필터정보</b-th>
                <b-th>삭제</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                  v-for="val in filterInfo"
                  :key="val.cdId"
              >
                <b-td>
                  {{ val.filterNm }}
                </b-td>
                <b-td>
                  <b-button
v-b-toggle.searchForm
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            size="sm"
                            variant="primary"
                            @click="goFilterModal(val.filterNm)"
                  >
                    확인
                  </b-button>
                </b-td>
                <b-td>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="danger"
                      @click="deleteFilter(val.filterNm)"
                  >
                    삭제
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

    </b-sidebar>

    <b-modal
        v-model="modalType"
        centered
        ok-only
        ok-title="확인"
        size="xl"
        title="필터조회내역"
    >
      <b-overlay
          :show="show"
          class="text-primary"
          rounded="xl"
      >

        <div>
          <filter-static-summary-table-renew />
        </div>
        <div>
          <filter-box-plot-chart-renew />
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BModal,
  BOverlay,
  BRow,
  BSidebar,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  VBModal,
  VBToggle,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { utils } from "@/utils/commUtils";
import FilterStaticSummaryTableRenew from "./FilterStaticSummaryTableRenew.vue";
import FilterBoxPlotChartRenew from "./FilterBoxPlotChartRenew.vue";

export default {
  components: {
    FilterStaticSummaryTableRenew,
    FilterBoxPlotChartRenew,
    BRow,
    BCol,
    BButton,
    BSidebar,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BModal,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    "b-modal": VBModal,
  },
  data() {
    return {
      modalType: false,
    }
  },
  computed: {
    ...mapGetters({
      filterInfo: "filter/getFilterInfo", // 필터 정보
      filterFetchedInfo: "filter/getFilterFetchedInfo",
      // 서머리 전용 신규생성
      filterSummaryInfo: "filter/getFilterSummaryInfo",
      show: "filter/getShowOverlay",
    }),
  },
  methods: {
    // 필터 정보 팝업을 열 때, 공통코드 데이터를 조회함
    openFilterSearchForm() {
      this.$store.dispatch("filter/FETCH_FILTER_INFO");
    },
    async goFilterModal(filterNm) {
      this.modalType = !this.modalType;

      // 받는 부분이 json 타입이라 강제 설정
      this.filterFetchedInfo.filterNm = filterNm;

      // 저장된 필터명으로 저장된 데이터 로드
      await this.$store.dispatch("filter/FETCH_FILTER_LOAD_DATA", this.filterFetchedInfo);

      if (this.filterFetchedInfo.openSvcId.length > 0) {
        await this.$store.dispatch("filter/FETCH_OPNSVC_LOAD_LIST", this.filterFetchedInfo);
      }

      await this.$store.dispatch("filter/FETCH_COMM_CODE_DATA_SUMMARY");
    },
    deleteFilter(filterNm) {
      utils.fnConfirm(this, "삭제하시겠습니까?", `[${filterNm}] 필터 조건이 삭제됩니다.`)
        .then(async (res) => {
          if (res.value) { // 사용자가 확인 눌럿을 경우
            // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            await this.$store.dispatch("filter/DELETE_FILTER_INFO", { filterNm });

            const resFlag = await this.$store.getters["filter/getResult"];
            utils.fnAlert(this, resFlag.resultMsg);
          }
        })
    }
  }
}
</script>

<style lang="scss">
.table-style {
  padding: 10px
}
</style>
